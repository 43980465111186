body {
  background-color: #fafafa;
}

td,
th {
  border-color: red;
  border-style: none;
  border-width: 0 !important;
  border-collapse: unset;
}

.first-container {
  margin-top: 50px;
  margin-bottom: 100px;
  display: block;
  /* max-width: 1140px; */
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 20px;
  text-align: left;
}

.dashboard-page .row .contain-box {
  margin-bottom: 15px !important;
}

.dashboard-box {
  min-height: 200px;
  /* height: 100%; */
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  padding: 10px 20px;
  margin: 0 auto;
  -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  -moz-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
  overflow: hidden;
  position: relative;
  max-width: 1440px;
}

.custom-btn {
  display: flex;
  /* height: 50px; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  /* flex: 1 1; */
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  outline: none !important;
  text-decoration: none !important;
  border: none;
}

.custom-btn.inline {
  margin: 0;
  margin-right: 10px;
  display: inline-block;
}

.custom-btn.mini {
  font-size: 10px;
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 20px;
}

.custom-btn.green {
  background-color: #72d830 !important;
  border-color: #72d830 !important;
  color: white !important;
}

.custom-btn.red {
  background-color: #ff0074 !important;
  border-color: #ff0074 !important;
  outline: none !important;
  color: white !important;
}

.custom-btn.grey {
  background-color: #ddd !important;
  border-color: #ddd !important;
  outline: none !important;
  color: white !important;
}

.custom-btn.grey:hover,
.custom-btn.grey:active,
.custom-btn.grey:focus,
.custom-btn.grey:not(:disabled):not(.disabled):active,
.show > .custom-btn.grey.dropdown-toggle {
  background-color: #aaa !important;
  border-color: #aaa !important;
  outline: none !important;
  border: none;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  color: white !important;
}

.custom-btn.red:hover,
.custom-btn.red:active,
.custom-btn.red:focus,
.custom-btn.red:not(:disabled):not(.disabled):active,
.show > .custom-btn.red.dropdown-toggle {
  background-color: #d35656 !important;
  border-color: #d35656 !important;
  outline: none !important;
  border: none;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  color: white !important;
}

.custom-btn.green:hover,
.custom-btn.green:active,
.custom-btn.green:focus,
.custom-btn.green:not(:disabled):not(.disabled):active,
.show > .custom-btn.green.dropdown-toggle {
  background-color: #5ac582 !important;
  border-color: #5ac582 !important;
  border: none;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  color: white !important;
}

.semicircle {
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 100%;
  top: -18px;
  right: -18px;
}

.dashboard-box .header-box {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: left;
  color: #333;
  border-bottom: 2px solid #ddd;
}

.dashboard-box .header-box h1 {
  font-size: 28px !important;
  line-height: 38px !important;
}

.dashboard-box .body-box .inline-data {
  font-size: 16px;
  height: 30px;
}

.dashboard-box .body-box .inline-data span,
.dashboard-box .body-box .inline-data strong {
  margin-right: 10px;
  margin-left: 10px;
}

.dashboard-box .body-box .inline-data .right {
  float: right;
}

.color-box {
  color: white !important;
}

.color-box .header-box {
  color: #fff;
  border-bottom: 0px solid #ddd;
}

.color-box.semicircle {
  display: none;
}

.blue {
  background-color: #00c2ef;
}

.text-blue {
  color: #00c2ef;
}

.green {
  background-color: #72d830;
}

.orange {
  background-color: #ffce00;
}

.red {
  background-color: #ff0074;
}

.image-contain img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

/*plan*/
.contain-buttons-plan {
  width: 240px;
  height: 70px;
  margin: 0 auto;
}

.contain-buttons-plan button {
  margin-top: 20px;
  width: 120px;
  height: 60px;
  border: none;
  font-size: 14px;
}

.contain-buttons-plan button.grey {
  background-color: #ccc;
}

/* .contain-buttons-plan .button1{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.contain-buttons-plan .button2{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */
.contain-card-plan {
  min-width: 300px;
  width: 30%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contain-card-plan .card {
  border: none;
}

.contain-card-plan .card-body {
  border: 1px solid #ddd;
}

.contain-card-plan .card-body p.card-text {
  font-size: 15px;
}

.contain-card-plan .card-header {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  text-align: center;
}

.contain-card-plan .card-header img {
  width: 120px;
}

.contain-card-plan .card-header .card-title {
  margin-top: 20px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.img-check {
  width: 20px;
  margin-right: 10px;
}

.del-add-remove-logEntry {
  display: flex;
  column-gap: 1rem;
  border-bottom: none;
}

.tags-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding-bottom: 1rem;
  color: white;
}

.tag {
  border: solid 1px;
  border-radius: 20%;
  padding: 10px 10px 10px 10px;
  background-color: #72d830;
}

.selected-tag {
  background-color: #72d830;
  color: white;
}

.tag:hover {
  cursor: pointer;
  background-color: #519c1e;
}

.tag:active {
  background: #5ac582;
}

.tag-search-input {
  margin-right: 10px;
  text-indent: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border: none;
  width: 100%;
  /* max-width: 500px; */
  height: 44px;
  min-width: 200px;
}

.project-link {
  text-decoration: none;
  color: black;
  font-size: large;
}

.project-link:hover {
  text-decoration: none;
  color: #72d830;
}

.pagination-wrapper {
  display: flex;
  background-color: #5ac582;
}

.limitPage {
  display: flex;
  column-gap: 0.5rem;
  width: fit-content;
  align-items: center;
  color: #0d6efd;
}

.limitPage-value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: #0d6efd;
}

.limitPage-value:hover {
  background-color: #dee2e6;
  cursor: pointer;
}

.limitPage-value:active {
  background-color: #aaa;
}

.limitPageActive {
  background-color: #0d6efd;
  color: white;
}

.collapseInfo {
  width: 100%;
  background-color: lightgray;
}

.projects-list {
  padding-top: 1rem;
  /* list-style: none; */
}

.sidebar {
  width: 320px;
}

.link-aside:hover {
  background: #72d830 0% 0% no-repeat padding-box;
  transition: 0.3s;
}

.dashboard-icon {
  margin-right: 20px;
  color: #272727;
}

.text-green {
  color: #72d830;
}

.dashboard-icon:hover {
  cursor: pointer;
  color: #72d830;
}

.icon-vai-sito-web {
  color: black;
  margin-right: 20px;
}

.user-icon {
  font-size: 40px;
}

.user-icon:hover {
  color: #00000029;
  transition: 0.3s;
}

.usericon-drop {
  right: 20px;
  top: 70px;
  background-color: white;
  border: solid 1px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: 2;
}

.usericon-items {
  border-bottom: solid 1px #f3f3f3;
}

.user-menu {
  text-decoration: none;
  color: #aaa;
}

.user-menu:hover {
  cursor: pointer;
  color: #72d830;
  text-decoration: none;
}

.select-language {
  border: none;
  background-color: white;
  border-radius: 10px;
  font-size: 20px;
}

.dashboard-leftbox {
  min-height: 250px;
  border-radius: 5px;
  background-color: #fff;
  margin: 0 auto;
  -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  -moz-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
  overflow: hidden;
  position: relative;
}

@media screen and (min-width: 1400px) {
  .mediaq-side {
    width: 320px;
  }
}

.grid-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.min-height {
  min-height: 278px;
}

.copy-hide-apikey-icons {
  right: 0;
  color: #707070;
}

.copy-apikey:hover {
  color: #333;
}

.bg-blue {
  background-color: #00c2ef;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: auto;
  gap: 10px 24px;
}

.project-item {
  border: 0px;
  padding: 10px;
  max-height: 100px;
  text-decoration: none;
  font-weight: 700;
  color: #000;
  border-radius: 5px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.09803921568627451);
  background: #fff;
  min-height: 100px;
  width: 100%;
  /* border: solid 2px #f3f3f3;
  padding: 10px;
  max-height: 100px;
  text-decoration: none;
  font-weight: bold;
  color: black;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #00000019;
  background: #ffffff;
  min-height: 100px;
  width: 100%; */
}

.project-item:hover {
  border: 0;
  transition: 0.5s;
  box-shadow: 2px 8px 6px rgba(0, 0, 0, 0.09803921568627451);
  /* border: solid 3px #aaa;
  transition: 0.5s; */
}

.tags-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 70px));
  grid-template-rows: auto;
  gap: 10px;
}

.tag-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 22px 8px 22px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 3px #00000019;
  border-radius: 100px;
  height: 32px;
  min-width: max-content;
}

.tag-item:hover {
  cursor: pointer;
  border: 2px solid #72d830;
}

.search-button {
  background-color: transparent;
  border: none;
  top: 13px;
  left: 15px;
}

.search-spinner {
  right: 10px;
  top: 10px;
}

.details {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  width: 80px;
  height: 38px;
  margin-top: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00c2ef;
  color: white;
}

.details:hover {
  cursor: pointer;
  background-color: #00acd3;
}

.select-limit-page {
  border: none;
  height: 35px;
  width: 70px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.pages {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.pages:hover {
  background-color: #00c2ef;
  color: white;
}

.active-page {
  background-color: #00c2ef;
  color: white;
}

.next-page-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 5px;
}

.next-page-icon:hover {
  background-color: #dedede;
}

.popup-dettagli {
  font-size: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.tag-inpopup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  border: 1px solid #72d830;
  border-radius: 100px;
  max-height: 40px;
  width: max-content;
  min-width: 50px;
  font-size: 12px;
}

.group-box-rename {
  width: 415px;
}

.create-group-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  width: 130px;
  height: 38px;
  color: #272727;
  border: none;
}

.create-group-button:is(:hover, :focus) {
  cursor: pointer;
  background-color: #72d830;
  color: white;
  box-shadow: 0px 0px 3px #00000029;
}

.group-button-edit {
  width: 80px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #00c2ef;
  /* color: #707070; */
  color: white;
}

.group-button-edit:hover {
  background-color: #00c2ef;
  color: white;
  cursor: pointer;
}

.group-button-delete {
  width: 80px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff3131;
  color: white;
}

.channel-button-disabled {
  width: 80px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #707070;
  color: white;
  cursor: pointer;
}

.group-button-delete:hover {
  background-color: #ff3131;
  color: white;
  cursor: pointer;
}

.popup-crea-gruppo {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: space-around;
}

.popup-crea-gruppo-input {
  margin-right: 10px;
  text-indent: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  border: none;
  width: 100%;
  max-width: 500px;
  height: 34px;
}

.group-cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  width: 130px;
  height: 38px;
  color: #272727;
  border: none;
}

.group-cancel-button:is(:hover, :focus) {
  background-color: #f3f3f3;
  color: #272727;
  cursor: pointer;
  box-shadow: 0px 0px 3px #00000029;
}

.delete-popup {
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  padding: 10px 20px;
  margin: 0 auto;
  -webkit-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  -moz-box-shadow: 0 5px 10px 0 rgb(0 0 0 / 16%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
  overflow: hidden;
  position: relative;
}

.check-user {
  width: 24px;
  height: 24px;
  background: #00c2ef 0% 0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
}

.unchecked-user {
  width: 24px;
  height: 24px;
  background: #a5a5a5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .logo-mediaq {
    width: 50px;
  }
  .hide-on-mobile {
    display: none;
  }
}

.edit-user-form {
  text-indent: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 34px;
}

.info-help {
  margin-bottom: 90px;
  left: 20px;
}

.bg-navbarMenu-isOpen {
  width: 100%;
  height: 100%;
  background: rgba(65, 65, 65, 0.295);
  position: absolute;
  z-index: 1;
}

.selected-menu {
  background: #72d830;
}

.pointer-hover:hover {
  cursor: pointer;
}

.right-top {
  right: 5px;
  color: #707070;
}

.max-w {
  max-width: 600px;
}

.w-70px {
  width: 70px;
}

.blueRandomColor {
  background-color: #00c2ef;
}

.greenRandomColor {
  background-color: #72d830;
}

.redRandomColor {
  background-color: #ff0074;
}

.yellowRandomColor {
  background-color: #ffce00;
}

.ok-alert-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 5px;
  color: #272727;
  width: 50px;
  height: 30px;
}

.ok-alert-button:hover {
  cursor: pointer;
  background-color: #707070;
  color: white;
}

@media screen and (max-width: 576px) {
  .drop-width-responsive {
    width: 200px;
  }
  .channel-name-code-input {
    width: 100%;
  }
  .name-code-w {
    width: 100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .drop-width-responsive {
    width: 100px;
  }
  .channel-name-code-input {
    width: 200px;
  }
}

@media screen and (max-width: 992px) {
  .new-project-label {
    width: 100px;
  }
  .group-box-rename {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .drop-width-responsive {
    width: 200px;
  }
  .channel-name-code-input {
    width: 200px;
  }
}

.input-width {
  width: 50%;
}

.container-width {
  max-width: 80%;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 758px) {
  .input-width {
    width: 100%;
  }
  .container-width {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .popup-dettagli {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .new-project-label {
    width: 130px;
  }
}

.modal-backdrop {
  background-color: white;
}

.modal-content {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  border: none;
  margin-bottom: 100px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-32 {
  font-size: 32px;
}

.title-font {
  font-size: 32px;
  font-weight: bold;
}

.separate-modal {
  border-top: 1px solid #dedede;
  margin-top: 20px;
}

.icon-modal {
  width: 51px;
  height: 51px;
  border-radius: 50px;
}

.subtitle-modal {
  color: #a5a5a5;
}

.properties-item-modal {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  min-width: 200px;
}

.send-log-properties {
  background: #f1f1f1 0% 0% no-repeat padding-box;
}

.trash-color {
  color: #a5a5a5;
}

.table-row-color {
  color: #5d5d5d;
}

.vertical-align {
  vertical-align: middle;
}

.project-logo {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

thead > tr {
  border-top: 0px;
}

.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f8f8f8;
}

.icon-project {
  font-size: 25px;
  width: 45px;
  height: 50px;
  border-radius: 5px;
}

.padding-end {
  padding-right: 55px;
}

.emoji-position {
  right: 0px;
  bottom: -4px;
}

.emoji-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.emoji-btn:disabled,
fieldset:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.emoji-btn:hover {
  color: #212529;
}

.type-title {
  color: #272727;
  opacity: 0.52;
  font-size: 26px;
  font-weight: bold;
}

.max-width {
  max-width: 1440px;
}

.btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: 5px;
  right: 0px;
}
